/**
 * animalpark theme custom JS main file
 */
jQuery(document).ready(function () {
  $('#fullwidth_play').on('click', function(e) {
    e.preventDefault();

    var videoFile = $('#videoplaceholder').data('file');
    var mimeType = $('#videoplaceholder').data('mime');

    var videoHtml = '<video width="100%" controls autoplay>' +
                    '<source src="' + videoFile + '" type="' + mimeType + '">' +
                    'Your browser does not support the video tag.' +
                    '</video>';

    $('#videoplaceholder').html(videoHtml);
    $('.fullwidth-video').find('.content').slideUp('fast');
});

  /**
   * Mobile Search
   */

  $("#mobile_search_trigger").click(function () { 

    // Toggle slide for the search bar
    $("#mobileSearch").slideToggle("fast");

    // Slide up the notice bar if it exists
    if ($("#noticeBar").length) {
      $("#noticeBar").slideToggle("fast");
    }
  });

  /**
   * Mobile Menu Trigger & Function
   */
  const burgerMenubtn = $('#burger');	
	const mobileNav = $('.mobile-slide-menu');
	const closeButton = $('.close'); 

	burgerMenubtn.click(function(){
		if(mobileNav.hasClass('mobile_nav_active')){
			mobileNav.removeClass('mobile_nav_active');
		} else {
			mobileNav.addClass('mobile_nav_active');
		}
	});
	closeButton.click(function(){
		if(mobileNav.hasClass('mobile_nav_active')){
			mobileNav.removeClass('mobile_nav_active');
		} else {
			mobileNav.addClass('mobile_nav_active');
		}
	});

  //homeheroslider
  if ($(".blog-feed ").length) {
    //Create slider on load
    blogSlider();
  }

  //copy to clip
  $("#copylink").click(function (e) {
    e.preventDefault;
    var dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert("Post URL copied to clipboard.");
  });


});



/*header*/
jQuery(document).ready(function ($) {
  let header = $(".scrolled-nav");
  let lastScrolltop = 0;

  hasScrolled();
  setTimeout(hasScrolled, 800);
  $(window).on("scroll", hasScrolled);

  function hasScrolled() {
    let top = $(window).scrollTop();
    if (top > 500 && top <= lastScrolltop) {
      //setTimeout(addFixed,400);
      //header.addClass('fixed-top');
      header.addClass("fixed-top");
    }

    if (top < 500) {
      header.removeClass("fixed-top");
    }

    if ((header.hasClass("fixed-top") && top > lastScrolltop) || top < 400) {
      //setTimeout(removeFixed, 500);
      header.removeClass("fixed-top");
    }
    lastScrolltop = $(window).scrollTop();
  }

  function addFixed() {
    header.addClass("fixed-top");
  }
  function removeFixed() {
    header.removeClass("fixed-top");
  }
});



if($('#video-placeholder').length){
  /**
   * Video Load on Scroll
   */
  document.addEventListener("DOMContentLoaded", function () {
    var observer = new IntersectionObserver(
      function (entries) {
        // Loop over the entries
        entries.forEach(function (entry) {
          // If the element is in view
          if (entry.isIntersecting) {
            // Get the placeholder element
            var videoPlaceholder = document.getElementById("video-placeholder");
            // Create the video element
            var video = document.createElement("video");
            video.autoplay = true;
            video.muted = true;
            video.loop = true;

            // Add the source to the video
            var source = document.createElement("source");
            source.src = videoData.url;
            source.type = videoData.type;
            video.appendChild(source);

            // Replace the placeholder with the video
            videoPlaceholder.innerHTML = "";
            videoPlaceholder.appendChild(video);

            // Unobserve the placeholder so it only loads once
            observer.unobserve(videoPlaceholder);
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // Target the video placeholder
    var videoPlaceholder = document.getElementById("video-placeholder");
    observer.observe(videoPlaceholder);
  });
}
if( $('.activities-slider').length ) {
  $('.activities-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, // Set variableHeight to true
    responsive: [
      {
        breakpoint: 1024,
        settings: {
        slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
        slidesToShow: 1,
        }
      },
      ]
  });
}

if( $('.animal-slider').length ) {
  $('.animal-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, // Set variableHeight to true
    responsive: [
      {
        breakpoint: 1024,
        settings: {
        slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
        slidesToShow: 1,
        }
      },
      ]
  });
  var $slider = $('.animal-slider');
  $('.arrow-left').on('click', function () {
    $slider.slick('slickPrev');
});

$('.arrow-right').on('click', function () {
    $slider.slick('slickNext');
});

}

if( $('.review-slider').length ) {
  $('.review-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, // Set variableHeight to true
    responsive: [
      {
        breakpoint: 1024,
        settings: {
        slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
        slidesToShow: 1,
        }
      },
      ]
  });
}
  
  // Assuming the arrow spans have the classes .arrow-left and .arrow-right
  $('.arrow-left').on('click', function () {
      $slider.slick('slickPrev');
  });

  $('.arrow-right').on('click', function () {
      $slider.slick('slickNext');
  });

function homeSlider() {
  $(".logo-slider").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // For screens less than or equal to 1200px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // For screens less than or equal to 992px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function searchesSlider() {
  $(".home-hero .searches").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    center: false,
    speed: 300,
    slidesToShow: 1.5,
    slidesToScroll: 1,
  });
}


if($('.workshop-facilitators').length){
  jQuery(document).ready(function($) {
    $('.workshop-facilitators').on('click', '.facilitators a', function(e) {
        e.preventDefault();
          var facilitatorID = $(this).data('id');
          var workshopID = $(this).closest('.facilitators').data('workshop-id');

          $.ajax({
              url: "/wp-admin/admin-ajax.php",
              type: 'POST',
              data: {
                  action: 'get_facilitator_data',
                  facilitator_id: facilitatorID,
                  workshop_id: workshopID
              },
              success: function(response) {
                  var $img = $('.background img');
                  $img.attr('src', response.data.main_image_url); 
                  $img.attr('srcset', response.data.main_image_srcset);
                  
                  $('.biography h3').text(response.data.name);
                  $('.biography big').text(response.data.qualifications_cs);
                  $('.biography p').text(response.data.bio);

                  $('.facilitators').html(response.data.facilitators_html);

                  // Handle the response here
                  console.log(response);
              },
              error: function(errorThrown) {
                  console.log(errorThrown);
              }
          });
      });
  });
}


function blogSlider() {
  $(".posts-slider").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1.5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function facilitatorSlider() {
  $(".facilitator-slider").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2300,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: 'linear', // This will create a smooth constant scrolling without acceleration
    slidesToShow: 6.8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 4.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1000, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2.2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 540, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      }
    ]
  });
}

/**
 * Facilitator Archive Region Filter
 */
jQuery(document).ready(function($) {
  $('.facil_filters a').on('click', function(e) {
      e.preventDefault();

      var regionId = $(this).data('region');
      $('.facil_filters a').removeClass('active');
      $(this).addClass('active');

      $.ajax({
        url: "/wp-admin/admin-ajax.php",
          type: 'POST',
          data: {
              'action': 'get_facilitators_by_region', // WP AJAX action hook
              'region_id': regionId // The selected region ID
          },
          success: function(response) {
              $('.facilitators-by-region').html(response); // Update the facilitators list
          }
      });
  });
});


function heroCatSlider() {
  $('.categories-slider').slick({
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust the speed of auto-scrolling
    arrows: true,
    prevArrow: $('.left'),
    nextArrow: $('.right'),
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
        {
            breakpoint: 1024, // Screen width up to 1024px
            settings: {
                slidesToShow: 3, // Show 3 slides for medium-sized screens
            }
        },
        {
            breakpoint: 768, // Screen width up to 768px
            settings: {
                slidesToShow: 2, // Show 2 slides for small-sized screens
            }
        },
        {
            breakpoint: 480, // Screen width up to 480px
            settings: {
                slidesToShow: 1, // Show 1 slide for extra small screens
            }
        }
    ]
  });
}
//accordion
if( $('.faqs').length ) {
  if( $('.accordion').length ) {
    var allPanels = $('.accordion dd').hide();
    
    $('.accordion dt > a').click(function() {

    var accordionItem = $(this).parents('.accordion_item');

    if(accordionItem.hasClass('active')){
      accordionItem.removeClass('active');
    } else {
      accordionItem.addClass('active');
    }

    if($(this).hasClass('active')){
      $(this).parent().next().slideUp();
      $(this).removeClass('active');
    } else {
      allPanels.slideUp();
      $('.accordion dt > a').removeClass('active');
      $(this).addClass('active');
      $(this).parent().next().slideDown();
      return false;
    }

    });
  }
  }




jQuery(document).ready(function($) {
  /*
  * Magnetic Buttons
  */

  var magnets = document.querySelectorAll('.magnetic')
  var strength = 50

  magnets.forEach( (magnet) => {
  magnet.addEventListener('mousemove', moveMagnet );
  magnet.addEventListener('mouseout', function(event) {
    TweenMax.to( event.currentTarget, 1, {x: 0, y: 0, ease: Power4.easeOut})
  } );
  });

  function moveMagnet(event) {
  var magnetButton = event.currentTarget
  var bounding = magnetButton.getBoundingClientRect()


  TweenMax.to( magnetButton, 1, {
    x: ((( event.clientX - bounding.left)/magnetButton.offsetWidth) - 0.5) * strength,
    y: ((( event.clientY - bounding.top)/magnetButton.offsetHeight) - 0.5) * strength,
    ease: Power4.easeOut
  })
  }
});


function facilitatorColSlider() {
  $(".facilitator_colour_slider").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2300,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: 'linear', // This will create a smooth constant scrolling without acceleration
    slidesToShow: 3.7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3.5, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.9, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 5, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1050, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 380, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.7, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      }
    ]
  });
  $(".facilitator_colour_slider_two").slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2300,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for a continuous scroll without pause
    cssEase: 'linear', // This will create a smooth constant scrolling without acceleration
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1590, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1300, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2.5, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1000, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 540, // For screens less than or equal to 668px
        settings: {
          slidesToShow: 1.8, // Adjust the number of slides as needed
          slidesToScroll: 1
        }
      }
    ]
  });
}

